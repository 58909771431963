import store from "@/store";
import axios from "axios";
import {ElMessage} from 'element-plus'

// 本地测试地址
// let baseUrl = "http://localhost:8095/"; 
// 服务器ip:springboot应用端口号()
let baseUrl = "https://www.yuyinsz.com/api/"; 
// 网址版本信息
let webVersion = "V1.2.6";

let webTitle = "余音阅谱"+webVersion;

// 创建axios示例
const httpService = axios.create({
    // url前缀- 'http:xxx.xxx'
    baseURL: baseUrl, // 需自定义
    //baseURL: process.env.VUE_APP_BASE_URL,
    // 请求超时时间
    timeout: 3000  // 需自定义
});

//httpService.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
httpService.defaults.headers["Content-Type"] = "application/json;charset=UTF-8";

// 添加请求和响应拦截器
// 添加请求拦截器
httpService.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    console.log("stort="+store.getters.GET_TOKEN)
    config.headers.token=store.getters.GET_TOKEN
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
httpService.interceptors.response.use(function(response){
    // 对响应数据做些什么
    return response;
}, function (error){
    if (error.response.data.code == 401 ) {
        logout();
    }
    // 对响应错误做点什么
    // const data = {
    //     msg: error.response.data.msg,
    //     code: error.response.data.code,
    //     status: error.response.status
    //   }
    //   throw data;
    return Promise.reject(error);
});

const logout =async ()=>{
    let result = await get("/logout")
    if ( result.data.code==200) {
        store.commit('SET_ROUTES_STATE', false);
        store.commit('RESET_TABS');
        store.dispatch('logout')
    }
}

/** 网络请求部分*/

/**
 * get请求
 * @param {*} url 请求地址
 * @param {*} params 参数
 * @returns 
 */
export function get(url, params ={}) {
    return new Promise((resolve, reject) => {
        //const CancelToken = axios.CancelToken;
        //const source = CancelToken.source();
        httpService({
            //cancelToken: source.token,
            url: url,
            method: 'get',
            params: params
        }).then(response =>{
            resolve(response);
        }).catch(error =>{
            if ( error.data ) {
                ElMessage({
                    type: 'error',
                    message: error.message
                })
            }
            else {
                if ( error.response ) {
                    ElMessage({
                        type: 'error',
                        message: error.response.data.msg
                    })
                }
                else {
                    ElMessage({
                        type: 'error',
                        message: error.message
                    })
                }
            }
            reject(error);
        });
        //source.cancel('Operation canceled by the user.');
    });
}

/**
 * post请求
 * @param {} url 
 * @param {*} params 
 * @returns 
 */
export function post(url, params={}) {
    //const CancelToken = axios.CancelToken;
    //const source = CancelToken.source();
    return new Promise((resolve, reject) => {
        httpService({
            //cancelToken: source.token,
            url: url,
            method: 'post',
            data: params
        }).then(response =>{
            console.log(response)
            resolve(response);
        }).catch(error =>{
            console.log(error)
            if ( error.data ) {
                ElMessage({
                    type: 'error',
                    message: error.message  // error.response.data.msg
                })
            }
            else {
                if ( error.response ) {
                    ElMessage({
                        type: 'error',
                        message: error.response.data.msg
                    })
                }
                else {
                    ElMessage({
                        type: 'error',
                        message: error.message
                    })
                }
            }
            resolve(error);//reject(error);
        });
        //source.cancel('Operation canceled by the user.');
    });
}

/**
 * 文件上传
 * @param {} url 
 * @param {*} params 
 * @returns 
 */
export function fileUpload(url, params={}) {
    return new Promise((resolve, reject) => {
        httpService({
            url: url,
            method: 'post',
            data: params,
            headers: {'Content-Type': 'multipart/form-data'}
        }).then(response =>{
            resolve(response);
        }).catch(error =>{
            reject(error);
        });
    });
}

export function getServerUrl() {
    return baseUrl;
}

export function getAppVersion() {
    return webVersion;
}

export function getAppTitle() {
    return webTitle;
}

export default {
    get,
    post,
    fileUpload,
    getServerUrl,
    getAppVersion,
    getAppTitle
}